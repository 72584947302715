<template>
  <div>
    <helloWorld />
    <div>
      <!-- <div class="page-bac">
        <img src="@/assets/ab1.webp" alt="">
      </div> -->
      <div class="person-info" style="margin-top:100px">
        <div style="position:relative">
          <!-- <changeAvatar :url="info.avatar" /> -->
          <el-upload
            class="upload-demo"
            :headers="upload.headers"
            action="1"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="uploadImg2">
            <img class="ava" v-if="info.avatar == ''" src="@/assets/avatar.jpg" alt="">
            <img class="ava" v-else :src="info.avatar" alt="">
            <div class="shadow">
              <i class="el-icon-camera" style="color:#fff;font-size:20px"></i>
            </div>
          </el-upload>
        </div>
        <p class="author-name">{{info.nickname}}</p>
        <div style="margin-top:20px">
          <span class="change" @click="tochange" style="margin-right:10px;"><i class="el-icon-user-solid"></i>修改密码</span>
          <span class="change" @click="goOut"><i class="el-icon-top-right"></i>退出登录</span>
        </div>
        <!-- <p class="string">{{info.userCode}}</p> -->
      </div>
      <div class="classification">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="创建" name="first">
            <div class="works" v-if="createdAlbumList.length > 0">
              <div class="works-item" v-for="(item, index) in createdAlbumList" :key="index" @click="toDetail(item)">
                <div class="img-box">
                  <img class="collcet-pic" :src="item.pic" alt="">
                </div>
                <div style="padding:0 14px">
                  <p class="works-name">{{item.colName}}</p>
                  <div class="creator" style="margin-bottom:0">
                    {{item.albumTitle}}
                  </div>
                  <div class="price" style="align-items:center">
                    <p>价格：<span>￥{{item.price}}</span></p>
                    <!-- <p>成交价：<span>￥{{item.transaction}}</span></p> -->
                    <div v-if="item.saleMode ==0" class="change-btn" @click.stop="changeCollect(item)">修改</div>
                  </div>
                  <div class="likenum" style="border-top:1px solid #eee">
                    <img src="@/assets/xihuan.svg" alt="">
                    <a>{{item.likes}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无藏品"></el-empty>
            </div>
            <pagination
              v-show="createTotal>0"
              :total="createTotal"
              :page-sizes="[12, 24, 36, 48, 50]"
              :page.sync="createParams.PageNum"
              :limit.sync="createParams.PageSize"
              @pagination="getCollect"
            />
          </el-tab-pane>
          <el-tab-pane label="买入" name="second">
            <!-- <div class="works" v-if="buyTotal !=0">
              <div class="works-item" v-for="(item, index) in buyList" :key="index" @click="toDetail(item)">
                <div class="img-box">
                  <img class="collcet-pic" :src="env+item.assetCollect.pic" alt="">
                </div>
                <div style="padding:0 14px">
                  <p class="works-name">{{item.colName}}</p>
                  <div class="price">
                    <p>价格：<span>￥{{item.price}}</span></p>
                  </div>
                  <div class="likenum" style="border-top:1px solid #eee">
                    <img src="@/assets/xihuan.svg" alt="">
                    <a>{{item.assetCollect.likes}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无收集藏品"></el-empty>
            </div> -->
            <el-table
              :data="buyList"
              style="width: 100%">
              <el-table-column
                prop="orderCode"
                label="订单编号"
                width="180">
              </el-table-column>
              <el-table-column
                prop="createdAt"
                label="时间"
                width="180">
              </el-table-column>
              <el-table-column
                label="藏品">
                <template slot-scope="scope">
                  <img style="width:64px;height:64px;object-fit: cover;" :src="env+scope.row.assetCollect.pic" alt="">
                </template>
              </el-table-column>
              <el-table-column
                prop="colName"
                label="名称">
                <template slot-scope="scope">
                  <a style="cursor:pointer" @click="toDetail(scope.row)">{{scope.row.colName}}</a>
                </template>
              </el-table-column>
              <el-table-column
                prop="fromNkname"
                label="卖家">
              </el-table-column>
              <el-table-column
                label="数量">
                <template>
                  <span>1</span>
                </template>
              </el-table-column>
              <el-table-column
                label="金额">
                <template slot-scope="scope">
                  <span>￥{{scope.row.price}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="支付状态">
                <template slot-scope="scope">
                  <!-- <span v-if="scope.row.status == 1">待支付</span> -->
                  <span v-if="scope.row.status == 1 && pc == false" @click="toPay">待支付</span>
                  <el-button v-if="scope.row.status == 1 && pc == true" type="danger" size="small" @click="toBuy(scope.row)">待支付</el-button>
                  <span v-if="scope.row.status == 3">已支付</span>
                  <span v-if="scope.row.status == 9">交易完成</span>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              v-show="buyTotal>0"
              :total="buyTotal"
              :page-sizes="[12, 24, 36, 48, 50]"
              :page.sync="buyParams.PageNum"
              :limit.sync="buyParams.PageSize"
              @pagination="getBuyList"
            />
          </el-tab-pane>
          <el-tab-pane label="卖出" name="five">
            <!-- <div class="works" v-if="sellTotal !=0">
              <div class="works-item" v-for="(item, index) in sellList" :key="index" @click="toDetail(item)">
                <div class="img-box">
                  <img class="collcet-pic" :src="env+item.assetCollect.pic" alt="">
                </div>
                <div style="padding:0 14px">
                  <p class="works-name">{{item.colName}}</p>
                  <p class="creator" style="margin-bottom:6px">购买者：{{item.toNkname}}</p>
                  <div class="price">
                    <p>价格：<span>￥{{item.price}}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无售出的藏品"></el-empty>
            </div> -->
            <el-table
              :data="sellList"
              style="width: 100%">
              <el-table-column
                prop="orderCode"
                label="订单编号"
                width="180">
              </el-table-column>
              <el-table-column
                prop="createdAt"
                label="时间"
                width="180">
              </el-table-column>
              <el-table-column
                label="藏品">
                <template slot-scope="scope">
                  <img style="width:64px;height:64px;object-fit: cover;" :src="env+scope.row.assetCollect.pic" alt="">
                </template>
              </el-table-column>
              <el-table-column
                prop="colName"
                label="名称">
              </el-table-column>
              <el-table-column
                prop="toNkname"
                label="买家">
              </el-table-column>
              <el-table-column
                label="数量">
                <template>
                  <span>1</span>
                </template>
              </el-table-column>
              <el-table-column
                label="金额">
                <template slot-scope="scope">
                  <span>￥{{scope.row.price}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="支付状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 1">待支付</span>
                  <!-- <el-button v-if="scope.row.status == 1" type="danger" size="small" @click="toBuy(scope.row)">待支付</el-button> -->
                  <span v-if="scope.row.status == 3">已支付</span>
                  <span v-if="scope.row.status == 9">交易完成</span>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              v-show="sellTotal>0"
              :total="sellTotal"
              :page-sizes="[12, 24, 36, 48, 50]"
              :page.sync="sellParams.PageNum"
              :limit.sync="sellParams.PageSize"
              @pagination="getBuyList"
            />
          </el-tab-pane>
          <el-tab-pane label="喜欢" name="third">
            <div class="works" v-if="likeTotal > 0">
              <div class="works-item" v-for="(item, index) in likeList" :key="index" @click="toDetail(item)">
                <div class="img-box">
                  <img class="collcet-pic" :src="env+item.assetCollect.pic" alt="">
                </div>
                <div style="padding:0 14px">
                  <p class="works-name">{{item.assetCollect.colName}}</p>
                  <p class="creator" style="margin-bottom:6px">{{item.assetCollect.creator.nickname}}</p>
                  <div class="price">
                    <p>价格：<span>￥{{item.assetCollect.price}}</span></p>
                    <!-- <p>成交价：<span>￥{{item.transaction}}</span></p> -->
                  </div>
                  <div class="likenum" style="border-top:1px solid #eee">
                    <img src="@/assets/xihuan.svg" alt="">
                    <a>{{item.assetCollect.likes}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无点赞藏品"></el-empty>
            </div>
            <pagination
              v-show="likeTotal>0"
              :total="likeTotal"
              :page-sizes="[12, 24, 36, 48, 50]"
              :page.sync="likeParams.PageNum"
              :limit.sync="likeParams.PageSize"
              @pagination="getLikeList"
            />
          </el-tab-pane>
          <el-tab-pane label="收藏" name="fourth">
            <div class="works" v-if="collectTotal > 0">
              <div class="works-item" v-for="(item, index) in collectList" :key="index" @click="toDetail(item)">
                <div class="img-box">
                  <img class="collcet-pic" :src="env+item.assetCollect.pic" alt="">
                </div>
                <div style="padding:0 14px">
                  <p class="works-name">{{item.assetCollect.colName}}</p>
                  <p class="creator" style="margin-bottom:6px">{{item.assetCollect.creator.nickname}}</p>
                  <div class="price">
                    <p>价格：<span>￥{{item.assetCollect.price}}</span></p>
                    <!-- <p>成交价：<span>￥{{item.transaction}}</span></p> -->
                  </div>
                  <div class="likenum" style="border-top:1px solid #eee">
                    <img src="@/assets/xihuan.svg" alt="">
                    <a>{{item.assetCollect.likes}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无收藏藏品"></el-empty>
            </div>
            <pagination
              v-show="collectTotal>0"
              :total="collectTotal"
              :page-sizes="[12, 24, 36, 48, 50]"
              :page.sync="collectParams.PageNum"
              :limit.sync="collectParams.PageSize"
              @pagination="getCollectList"
            />
          </el-tab-pane>
          <el-tab-pane label="动态" name="six">
            <el-table
              :data="transactionList"
              style="width: 100%">
              <el-table-column
                label="商品"
                width="220">
                <template slot-scope="scope">
                  <div class="transacte-first">
                  <img :src="scope.row.assets" alt="">
                  <div style="width:auto !important;text-align:left">
                    <p class="transacte-title">{{scope.row.author}}</p>
                    <p class="transacte-id">{{scope.row.title}}</p>
                  </div>
                </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="unitPrice"
                label="价格">
              </el-table-column>
              <el-table-column
                prop="num"
                label="数量">
              </el-table-column>
              <el-table-column
                prop="saleName"
                label="从">
              </el-table-column>
              <el-table-column
                prop="buyerName"
                label="到">
              </el-table-column>
              <el-table-column
                prop="created"
                width="180px"
                label="日期">
              </el-table-column>
            </el-table>
            <!-- <div class="actived">
              <div class="table-head">
                <div style="width:16%">商品</div>
                <div style="width:16%">价格</div>
                <div style="width:16%">数量</div>
                <div style="width:16%">从</div>
                <div style="width:16%">到</div>
                <div style="width:16%">日期</div>
              </div>
              <div class="table-body" v-for="(item, index) in transactionList" :key="index">
                <div class="transacte-first">
                  <img :src="item.assets" alt="">
                  <div style="width:auto !important;text-align:left">
                    <p class="transacte-title">{{item.author}}</p>
                    <p class="transacte-id">{{item.title}}</p>
                  </div>
                </div>
                <div style="width:16%" class="transaction-price">{{item.unitPrice}}</div>
                <div style="width:16%" class="transaction-num">{{item.num}}</div>
                <div style="width:16%" class="transaction-form">{{item.saleName}}</div>
                <div style="width:16%" class="transaction-form">{{item.buyerName}}</div>
                <div style="width:16%" class="transaction-time">{{item.created}}</div>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="专辑" name="seven">
            <div class="fZZKFU all-fZZKFU" v-if="albumTotal > 0">
              <div class="fZZKFU-item" v-for="(item, index) in userAlbumList" :key="index" @click="toAuthor(item)">
                <img class="fZZKFU-bac" :src="item.coverPic" alt="">
                <div style="text-align:center">
                  <img v-if="item.creator.avatar!=''" class="fZZKFU-avatar" :src="item.avatar" alt="">
                  <img  class="fZZKFU-avatar" v-else src="@/assets/avatar.jpg" alt="">
                </div>
                <p class="fZZKFU-name">{{item.title}}</p>
                <p class="from">来自<span>{{item.creator.nickname}}</span></p>
                <p class="fZZKFU-desc">{{item.desc}}</p>
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无专辑"></el-empty>
            </div>
            <pagination
              v-show="albumTotal>0"
              :total="albumTotal"
              :page-sizes="[12, 24, 36, 48, 50]"
              :page.sync="albumParams.PageNum"
              :limit.sync="albumParams.PageSize"
              @pagination="getUserAlbum"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
// import changeAvatar from './changeAvatar'
import { getCodeBase, changeAvatars, getCollectMyList, getAlbum, downImg,getAlbumList, toBuyList, getMyLike, getMyCollect, signOut } from '../../api/index';
import { mapGetters } from 'vuex'
export default {
  name: 'Detail',
  components: {
    helloWorld
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
    if (this.$route.query.type && this.$route.query.type == 'success') {
      this.activeName = 'second'
      this.getBuyList()
    }
    if (this._isMobile()) {
      this.pc = false
    } else {
      this.pc = true
    }
    this.getAlbum()
    this.getInfo()
  },
  data() {
    return {
      pc: false,
      env: process.env.VUE_APP_BASE_API,
      transactionList: [
        {
          num: '12',
          unitPrice: '25.00',
          amount: '18.8',
          saleImg: require(`@/assets/saleImg3.jpg`),
          saleName: 'Summer',
          buyerImg: require(`@/assets/saleImg4.jpg`),
          buyerName: '不美不媚',
          created: '2022-05-20 20:14:56',
          author: '亚联融汇科技有限公司',
          title: 'ARTSYCOLLECTOR #021',
          assets: require(`@/assets/free2.jpg`),
        }
      ], //交易记录
      activeName: 'first',
      info: {},
      shadowBox: false,
      // 上传参数
      upload: {
        // 是否禁用上传
        isUploading: false,
        // 设置上传的请求头部
        headers: { Authorization: this.accessToken },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + "/user/change-pwd"
      },
      createdAlbumList: [],
      albumList: [],
      createTotal: 0, //创建个数
      createParams: {
        PageNum: 1,
        PageSize: 12,
      },
      buyParams: {
        PageNum: 1,
        PageSize: 12,
        userType: 'buyer'
      },
      buyList: [],
      buyTotal: 0,
      sellParams: {
        PageNum: 1,
        PageSize: 12,
        userType: 'seller'
      },
      sellList: [],
      sellTotal: 0,
      likeList: [],
      likeParams: {
        PageNum: 1,
        PageSize: 12
      },
      likeTotal: 0,
      collectParams: {
        PageNum: 1,
        PageSize: 12
      },
      collectTotal: 0,
      collectList: [],
      userAlbumList: [],
      albumParams: {
        PageNum: 1,
        PageSize: 12
      },
      albumTotal: 0,
    }
  },
  methods: {
    toPay() {
      this.$message.error('请在电脑端进行支付！')
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    // 退出登录
    goOut() {
      this.$confirm('确定退出当前账户吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        signOut().then(res=> {
          if(res.code === 0 ){
            this.$store.dispatch('accessToken','')
            localStorage.removeItem('verify')
            this.$store.dispatch('userCode','')
            this.$router.push('/')
            this.tk = ''
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    toBuy(row) {
      let obj = {
        colCode: row.colCode,
        orderCode: row.orderCode
      }
      let str = JSON.stringify(obj);
      this.$router.push({ path: "./pay", query: { str } })
    },
    toAuthor(row) {
      this.$router.push('./author?title=' + row.title + '&albumId=' + row.id)
    },
    // 收藏列表
    getCollectList() {
      getMyCollect(this.collectParams).then(res => {
        if(res.code == 0) {
          this.collectList = res.data.list
          this.collectTotal = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 点赞列表
    getLikeList() {
      getMyLike(this.likeParams).then(res => {
        if (res.code == 0) {
          this.likeList = res.data.list
          this.likeTotal = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 收集
    getBuyList() {
      toBuyList(this.buyParams).then(res => {
        if (res.code == 0) {
          this.buyList = res.data.list
          this.buyTotal = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 售出
    getSellList() {
      toBuyList(this.sellParams).then(res => {
        if (res.code == 0) {
          this.sellList = res.data.list
          this.sellTotal = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getAlbum() {
      getAlbum().then(res => {
        if (res.code == 0) {
          this.albumList = res.data.list
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getUserAlbum() {
      getAlbumList(this.albumParams).then(res => {
        if (res.code == 0) {
          this.userAlbumList = res.data.list
          this.albumTotal = res.data.total
          if(res.data.total ==0) {
            return
          }
          for (var s = 0; s < this.userAlbumList.length; s++) {
            this.userAlbumList[s].coverPic = this.env + this.userAlbumList[s].coverPic
            if (this.userAlbumList[s].creator.avatar != '') {
              this.userAlbumList[s].avatar = this.env + this.userAlbumList[s].creator.avatar
            }
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 修改
    changeCollect(item) {
      this.$router.push('./created?colCode=' + item.colCode)
    },
    // 创建的专辑
    getCollect() {
      // this.createParams.createdBy = this.info.id
      getCollectMyList(this.createParams).then(res => {
        if (res.code === 0) {
          if(res.data.total == 0) {
            console.log(111)
          } else {
            this.createTotal = res.data.total
            this.createdAlbumList = res.data.list
            for (var c = 0; c < this.createdAlbumList.length; c++) {
              if (this.createdAlbumList[c].saleMode != 0 ){
                this.createdAlbumList[c].pic = this.env+this.createdAlbumList[c].pic
              }else {
                this.getCollcetPic(c, this.createdAlbumList[c].pic)
                // let a = {
                //     path: this.createdAlbumList[c].pic
                //   }
                //   downImg(a).then(res => {
                //     let blob = new Blob([res]);   // 返回的文件流数据
                //     let url = window.URL.createObjectURL(blob);  // 将他转化为路径
                //     this.createdAlbumList[c].pic = url;
                //   })
              }
              for (var a = 0; a < this.albumList.length; a++) {
                if (this.createdAlbumList[c].albumId == this.albumList[a].id) {
                  this.$set(this.createdAlbumList[c], 'albumTitle', this.albumList[a].title)
                }
              }
            }
          }
        } else {
          this.$message.error(res.message);
        }
      })
    },
    getCollcetPic(i, pic) {
      let a = {
        path: pic
      }
      downImg(a).then(res => {
        let blob = new Blob([res]);   // 返回的文件流数据
        let url = window.URL.createObjectURL(blob);  // 将他转化为路径
        this.createdAlbumList[i].pic = url;
      })
    },
    beforeUpload(file) {
      const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg'
      const isLt0M = file.size
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isJPG) {
        this.$message.error('上传头像只能为png/jpg/jpeg格式')
      }
      if (!isLt0M || !isLt10M) {
        this.$message.error('上传头像图片大小必须大于0字节并且小于10M!')
      }
      return isJPG && isLt0M && isLt10M
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    uploadImg2(param) {
      const fileObj = param.file
      // this.getBase64(fileObj).then(res => {
        // let obj ={
        //   avatar: fileObj
        // }
        let data = new FormData()
        data.append('avatar', fileObj)
        changeAvatars(data).then((res) => {
          if (res.code === 0) {
            this.$message.success('头像修改成功')
            this.getInfo()
          } else {
            this.$message.error(res.message)
          }
        // })
      })
    },
    getInfo() {
      let data={
        userCode: this.userCode
      }
      getCodeBase(data).then(res => {
        if (res.code == 0) {
          this.info = res.data
          this.getCollect()
          if (res.data.avatar == '') {
            return
          }
          this.info.avatar = this.env + this.info.avatar
        } else {
          this.$message.error(res.message)
          this.$router.push('./')
        }
        
      })
    },
    handleClick(tab) {
      if (tab.index == 0) {
        this.getCollect()
      } else if (tab.index == 1) {
        this.getBuyList()
      } else if (tab.index == 2) {
        this.getSellList()
      } else if (tab.index == 3) {
        this.getLikeList()
      } else if (tab.index == 4) {
        this.getCollectList()
      } else if (tab.index == 6) {
        this.getUserAlbum()
      }
    },
    toDetail(row) {
      // this.$router.push({path: './detail', query: {img: row.img, title: row.title, author: row.author, price:row.price, transaction: row.transaction, like: row.like, view: row.view, id: row.id, desc: row.desc,avatar: row.avatar}});
      this.$router.push('./detail?colCode='+row.colCode)
    },
    tochange() {
      console.log(111)
      this.$router.push('./ucenterEditInfo')
    }
  }
}
</script>
<style scoped>
.all-fZZKFU {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-width: 1180px;
  margin: auto;
  margin-top: 20px;
  width: 100%;
}
.fZZKFU-item {
  margin: 20px 14px;
  padding-bottom: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
}
.fZZKFU-bac {
  width: 100%;
  cursor: pointer;
  height: 200px;
  object-fit: cover;
}
.fZZKFU-item:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  margin-top: 16px;
}
.fZZKFU-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: -32px;
  cursor: pointer;
}
.fZZKFU-name {
  text-align: center;
  font-size: 14px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  width: 80%;
  margin: auto;
  cursor: pointer;
}
.from {
  text-align: center;
  font-size: 12px;
  line-height: 26px;
  color: #999;
  margin-top: 6px;
}
.from span {
  color: rgb(32, 129, 226);
  margin-left: 4px;
  cursor: pointer;
}
.fZZKFU-desc {
  font-size: 12px;
  line-height: 20px;
  color: #999;
  width: 80%;
  margin: auto;
  word-break: break-all;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
margin-top: 6px;
cursor: pointer;
}
.change-btn {
  padding: 1px 18px;
  background: #02204E;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  letter-spacing: 1px;
  font-size: 12px;
  /* width: 230px; */
  line-height: 30px;
}
.transacte-first {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
}
.transacte-first img {
  width: 40px;
  border-radius: 50%;
  margin-right: 4px;
  height: 40px;
}
.transacte-title {
  font-weight: 500;
    font-size: 12px;
    color: rgb(112, 122, 131);
}
.transacte-id {
  font-weight: 600;
    font-size: 12px;
    color: rgb(4, 17, 29);
    margin-top: 2px;
}
.ava:hover+.shadow{
  display: grid;
}
.shadow:hover {
  display: grid;
}
.shadow{
  width:90px;
  height:90px;
  background:rgba(0,0,0,0.5);
  border-radius:50%;
  position:absolute;
  top:-50px;
  z-index:1;
  display:flex;
  align-items:center;
  justify-content:center;
  display: none;
}
.item_bock {
 display: flex;
 align-items: center;
 justify-content: space-between;
 height:94px;
 width: 300px;
 padding:0px 24px 0px 38px;
 border-bottom: 1px solid #f7f7f7;
 background: #fff;
}
.head_p {
 height:132px;
}
.head_img{
 height: 90px;
}
.head_img img{
 width:90px;
 height:90px;
 border-radius:50px
}
.setting_right{
 display: flex;
 height: 37px;
 justify-content: flex-end;
 align-items: center;
}
.hiddenInput{
 display: none;
}
.caption {
 color: #8F8F8F;
 font-size: 26px;
 height: 37px;
}
.change {
  font-size: 10px;
  cursor: pointer;
  padding: 6px 8px;
  border: 1px solid #ccecff;
  border-radius: 4px;
  color: #1b9aee;
  font-weight: initial;
  margin-left: 6px;
  /* margin-top:-2px */
}
.change i {
  margin-right: 4px;
}
.content-1180 {
  max-width: 1180px;
  margin: auto;
}
.page-bac img{
  width: 100%;
  height: 150px;
}
.person-info {
  text-align: center;
}
.person-info img {
  width: 90px;
  height: 90px;
    border-radius: 50%;
    margin-top: -50px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.author-name {
  font-size: 22px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.string {
  font-size: 12px;
    color: rgb(112, 122, 131);
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}
.classification {
  max-width: 1180px;
  margin: auto;
  margin-top: 20px;
}
.works {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1180px;
  margin: auto;
  margin-top: 20px;
}
.works-item {
  margin: 10px 20px;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.works-item img {
  width: 100%;
  object-fit: cover;
}
.works-item:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
}
.img-box {
  width: 100%;
  max-height: 250px;
  text-align: center;
}
.collcet-pic {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  height: 230px;
}
.works-name {
  font-size: 18px;
  margin-top:4px;
  display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1; /*超出几行后省略号*/
overflow: hidden;
}
.creator {
  font-size: 12px;
  color: #666;
  margin-top:6px;
  margin-bottom: 12px;
}
.price {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 6px;
  padding-bottom: 10px;
  /* border-bottom: 1px solid #eee; */
}
.price p {
  color: #999;
}
.price p span {
  color: #333;
}
.likenum {
  padding: 8px 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.likenum a {
  font-size: 14px;
  color: #999;
  /* margin-left: 4px; */
}
.likenum img {
  width: 18px !important;
  margin-right: 4px;
}
.actived {
  max-width: 1180px;
  margin: auto;
  border: 1px solid #eee;
}
.table-head {
  display: flex;
  text-align: center;
  padding: 15px 0;
  background: #f8f8f8;
}
.table-body {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border: 1px solid #eee;
  text-align: center;
}
.item-info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.item-info img {
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 4px;
  height: 40px;
}
.category-name {
  font-weight: 500;
    font-size: 12px;
    color: rgb(112, 122, 131);
    cursor: pointer;
    margin-top: 2px;
}
.code {
  /* font-weight: 600; */
    font-size: 14px;
    color: rgb(4, 17, 29);
    cursor: pointer;
    margin-top: 4px;
}
.transaction-price {
  color: rgb(53, 56, 64);
  font-size: 14px;
}
.transaction-num {
  color: rgb(53, 56, 64);
  font-size: 14px;
}
.transaction-form {
  color: rgb(24, 104, 183);
  font-size: 14px;
  cursor: pointer;
}
.transaction-time {
  color: rgb(53, 56, 64);
  font-size: 14px;
}
.table-head div {
  font-size: 14px;
  color: rgb(53, 56, 64);
}
.change-avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: auto;
  background: rgba(4, 17, 29, 0.6);
  line-height: 88px;
  position: relative;
  margin-top: -94px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  z-index: 2;
}
@media screen and (max-width: 720px){
  .all-fZZKFU{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .works{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 720px) and (max-width: 900px){
  .works{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .all-fZZKFU{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
<style>
/* .classification ul {
 display: flex;
 justify-content: center;
} */
.classification .el-tabs__item {
  padding: 0 40px !important;
}
.classification .el-tabs__item.is-active {
  color: #303133 !important;
}
.classification .el-tabs__active-bar {
  width: 70px !important;
  left: -21px !important;
}
</style>